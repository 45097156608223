<template>
  <div>
    <b-row>
      <b-col class="col-md-6 col-12">
        <Breadcrumbs main="" title="Service Mapping" class="showBreadcrumbs"/>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col xl="12" md="">
        <b-card title="" type="gradient-red" sub-title="" class="mb-4">
          <b-row class="align-items-start">
            <b-col class="col-md-6 col-12">
              <span class="custome-title">Service Mapping</span>
            </b-col>
            <b-col class="col-md-6 col-12 d-flex justify-content-end align-items-start">
              <b-form-group
                label-cols="7"
                label="Per page"
                class="col-6 md-0 custom-font-18 text-right"
                >
                <b-form-select
                  class=" border custom-font-24"
                  v-model="perPage"
                  :options="pageOptions"
                  >
                </b-form-select>
              </b-form-group>
              <button class="btn custom-btn" @click="OpenServiceMap()">
                Add Service Mapping</button>
            </b-col>
          </b-row>

          <b-row class="table-responsive datatable-vue">
            <b-table 
              class="text-left custom-font-16"
              :fields="serviceMapperFields" 
              striped hover 
              :items="servicesMapData" 
              :busy="isBusy" 
              stacked="md"
              :filter="filter"
              :current-page="currentPage"
              :per-page="perPage"
              @filtered="onFiltered"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              >
              <template #cell(sid)="row">
                <span v-if="serviceList.filter(item=> item.value==row.item.sid).length > 0">
                  {{  serviceList.filter(item=> item.value==row.item.sid)[0].text }}
                </span>
              </template>
              <template #cell(reason)="row">
                <span v-if="reasonOptions.filter(t=> t.value == row.item.reason).length > 0">
                  {{reasonOptions.filter(t=> t.value == row.item.reason)[0].text }}
                </span>
              </template>
              <template #cell(action)="row">
                  <button
                    size="sm"
                    @click="editServiceMap(row.item)"
                    class="btn text-blue btn-xs mr-1"
                  >
                  <feather type="edit"></feather>
                  </button>
                  <button
                    size="sm"
                    @click="deleteServiceMap(row.item._id)"
                    class="btn text-danger btn-xs"
                  >
                  <feather type="trash-2"></feather>
                  </button>
                </template>
                <template #table-busy>
                  <div class="col-md-12" >
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
                </div> 
              </template>
            </b-table>
          </b-row>
          <b-col class="mt-3 p-0">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0 custom-font-16"
              ></b-pagination>
            </b-col>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="service_map_model"
      id="service-mapping"
      size="lg"
      title="Service Mapping"
      class="theme-modal"
      no-close-on-backdrop
      hide-footer
      @hide="getServiceMap()"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-12">
            <b-card class="p-2">
              <div class="row">
                <label class="col-md-2 col-12">Service</label>
                <b-form-select
                  class="col-md-8 col-12"
                  v-model="input.sid"
                  :options="serviceList"
                ></b-form-select>
              </div>
              <div class="row mt-4">
                <label class="col-md-2 col-12">Reason</label>
                <b-form-select
                  class="col-md-8 col-12"
                  v-model="input.reason"
                  :options="reasonOptions"
                ></b-form-select>
              </div>
              <div class="row mt-4">
                <label class="col-md-2 col-12">Value</label>
                <b-form-input
                  class="col-md-8 col-12"
                  v-model="input.svalue"
                  placeholder="value"
                ></b-form-input>
              </div>
              <div class="row mt-4 d-flex justify-content-center">
                <b-button
                  class="col-md-3 col-12"
                  variant="primary"
                  @click="Submit()"
                  :disabled="isBusy"
                  >{{ editServicemap?'Edit':'Submit'}}</b-button
                >
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>



<script>
import ServiceInfoApi from "../../../services/ServiceInfo";
import config from "../../../services/globalLit";
export default {
  name: "ServiceMapping",
  data() {
    return {
      input: {
        sid: null,
        reason: null,
        svalue: "",
      },
      serviceList: [{ value: null, text: "Please select service" }],
      reasonOptions: [
        { value: null, text: "Please select reason" },
        { value: "1", text: "host" },
        { value: "2", text: "url"}
      ],
      services: [],
      servicesMapData: [],
      service_map_model: false,
      isBusy: false,
      serviceMapperFields: [
        { key: "sid", label: "Service", sortable: true },
        { key: "reason", label: "Reason", sortable: true },
        { key: "svalue", label: "Value", sortable: true },
        { key: "action", label: "Action" }
      ],
      editServicemap:false,
      totalRows: 0,
      filter: null,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 50, 100, 200],
      sortBy: "sid",
      sortDesc: true,
      sortDirection: "desc",
    };
  },
  mounted() {
    this.getServiceMap();
    this.getService();
  },
  methods: {      
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
    },
    async getService() {
      let response = await ServiceInfoApi.get();
      if (response.result) {
        this.services = response.data;
        this.serviceList = [{ value: null, text: "Please select service" }];
        this.services.map((t) => {
          this.serviceList.push({
            value: t._id,
            text: t.sp_name + " - " + t.title,
          });
        });
      }
    },
    async getServiceMap() {
      this.input = {sid: null,reason: null,svalue: ""};
      let response = await ServiceInfoApi.getServiceMapping();
      if (response.result) {
        this.servicesMapData = response.data;
        this.totalRows = this.servicesMapData.length;
      }
    },
    async Submit() {
      if (this.validate()) {
        this.isBusy = true;
        var response
        if (this.editServicemap) {
          var payload = {
            "smid": this.input._id,
            "changeBody": {
              "sid": this.input.sid,
              "reason": this.input.reason,
              "svalue": this.input.svalue
            }
          }
          response = await ServiceInfoApi.editServiceMapping(payload);
          this.editServicemap = false;
        } else {
          response = await ServiceInfoApi.addServiceMapping(this.input);
        }
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
          onComplete: () => {
            this.service_map_model = false;
            this.getServiceMap();
            this.isBusy = false;
          }
        });
      }
    },
    validate(){
      const showToast = (message) => {
          this.$toasted.show(message, { theme: 'outline', position: 'bottom-center', type: 'error', duration: 2000 });
      };
      if(this.input.sid == null || this.input.sid == ''){
        showToast("Please select service");
        return false;
      }
      if(this.input.reason == null || this.input.reason == ''){
        showToast("Please select reason");
        return false;
      }
      if(this.input.svalue == null || this.input.svalue == ''){
        showToast("Please enter value");
        return false;
      }
      if(this.input.reason == "1" && config.validateHost(this.input.svalue) == false){
        showToast("Please enter valid host value");
        return false;
      }

      if(this.input.reason == "2") {
        //validate given value is keyword that not include any comma or space or dot
        if(config.validateKeyword(this.input.svalue) == false){
          showToast("Please do not use host value. Use only keyword");
          return false;
        }
      }
      return true;  
    },
    OpenServiceMap() {
      this.input = {
        sid: null,
        reason: null,
        svalue: "",
      };
      this.editServicemap = false;
      this.service_map_model = true;
    },
   async deleteServiceMap(id){
    if(confirm("Are you sure you want to delete this service mapping?")){
      let payload = {"smid":id}
      let response = await ServiceInfoApi.deleteServiceMapping(payload);
      let variant = response.result ? "success" : "error";
      this.$toasted.show(response.message, {
        theme: "outline",
        position: "bottom-center",
        type: variant,
        duration: 2000,
      });
      this.getServiceMap();
      } 
    },
    editServiceMap(data){
      this.editServicemap = true;
      this.input = data;
      this.service_map_model = true;
    }
  },
};
</script>
<style scoped>
.showBreadcrumbs {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .custom-font-18,
  .custom-font-24 {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 991px) {
  .showBreadcrumbs {
    display: block !important;
  }
}
</style>